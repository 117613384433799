.about_hero{
    background: linear-gradient(80deg, #1b0004, #380008);
    min-height: 100vh;
    padding-top: 70px;
}

.about_content{
    text-align: center;
    padding: 0px 50px;
}

.about_hero .hello_there{
    font-size: 15px;
    color:white;
    font-family:"montserrat-medium", sans-serif;
    padding:15px;
}

.about_hero .about_main_heading{
    font-size: 20px;
    font-weight: 600;
    color:#c4c4c4;
    font-family:"montserrat-medium", sans-serif;
    position: relative;
    display: inline-block;
}

.about_hero .about_main_heading::before {
    content: "";
    display: block;
    height: 2px;
    background: linear-gradient(80deg, #ffffff, #7a0012, #ffffff);
    margin-top: 10px;
    margin-bottom: 10px;
    animation: slideLine 3s infinite;
}

.about_hero .about_main_heading::after {
    content: "";
    display: block;
    height: 2px;
    background: linear-gradient(80deg, #ffffff, #7a0012, #ffffff);
    margin-top: 10px;
    margin-bottom: 10px;
    animation: slideLine 3s infinite;
}

.about_content .main_about {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 20px;
}

.main_about .about_image {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

@keyframes slideLine {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

.about_text{
    font-size: 20px;
    line-height: 1.6;
    text-align: center;
    color: #c4c4c4;
}

.main_about .about_text .about_text_paragraph{
    text-align: justify;
}

/* about card */
.about_card_container {
    padding:20px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.about_card {
    padding: 16px;
    border-radius: 8px;
    background: linear-gradient(80deg, #1b0004, #3b0009);
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #ddd;
    margin: 10px;
    text-align: center;
    width: 26%;
    min-width: 200px;
}

.about_card_image {
    width: 100%;
    border-radius: 8px;
}

.about_card_title {
    font-size: 24px;
    font-weight:700;
    margin: 10px 0;
    color:#c4c4c4;
}
  
.about_card_description {
    font-size: 18px;
    color: #c4c4c4;
}

.about_card_acheivement{
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #ddd;
    margin: 10px;
    text-align: center;
    width: 18%;
    min-width: 100px;
}

.about_card_acheivement .about_card_achivement_title{
    font-size: 20px;
    font-weight:700;
    margin: 10px 0;
    color:#a7a6a6;
}

.about_card_acheivement .about_card_achivement_description{
    font-size: 28px;
    font-weight:700;
    margin: 10px 0;
    color:#c4c4c4;
}

@media (max-width:1200px) {
    .about_hero .about_main_heading{
        font-size: 17px;
    }
    .about_text{
        font-size: 17px;
    }
    .about_card_acheivement .about_card_achivement_title{
        font-size: 26px;
    }
}

@media (max-width:1057px) {
    .about_card_acheivement{
        width: 15%;
    }
    .about_card_acheivement .about_card_achivement_title{
        font-size: 20px;
    }
}

@media (max-width:991px) {
    .about_content{
        padding: 0px 40px;
    }
    .about_hero .about_main_heading{
        font-size: 15px;
    }
    .about_hero .about_main_heading::before {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .about_hero .about_main_heading::after {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .main_about .about_image {
        height: 200px;
    }
    .about_text{
        font-size: 13px;
    }
    .about_card {
        width:40%;
    }
    .about_card_acheivement .about_card_achivement_title{
        font-size: 18px;
    }    
    .about_card_acheivement .about_card_achivement_description{
        font-size: 20px;
    }
}

@media (max-width:851px) {
    .about_card_acheivement .about_card_achivement_title{
        font-size: 16px;
    } 
}

@media (max-width:768px) { 
    .about_hero{
        padding-top: 50px;
        padding-bottom: 50px;
    } 
    .about_content{
        padding: 0px 30px;
    }
    .main_about .about_image {
        height: 150px;
    }
    .about_card_acheivement{
        width: 40%;
        min-width: 100px;
    }
}


@media (max-width:691px) {      
    .about_hero .about_main_heading{
        font-size: 20px;
    }     
    .about_content .main_about {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .main_about .about_image {
        order: 2;
        flex: 1;
        margin-right: 20px;
    }
    .about_text{
        order: 1;
        font-size: 18px;
        flex: 2;
        text-align: center;
    }
    .about_card {
        width:100%;
        min-width: 200px;
    }
    .about_card_acheivement{
        width: 35%;
        min-width: 100px;
    }
}

@media (max-width:561px) {
    .about_content{
        padding: 0px 20px;
    }
    .about_hero .about_main_heading{
        font-size: 18px;
    }
    .about_text{
        font-size: 16px;
    }
    .about_card_acheivement{
        width: 32%;
        min-width: 100px;
    }
}

@media (max-width:421px){
    .about_content{
        padding: 0px 15px;
    }
    .about_hero .about_main_heading{
        font-size: 16px;
    }
    .about_content .main_about {
        padding:20px 10px;
    }
    .about_text{
        font-size: 14px;
    }
    .about_card_acheivement{
        width: 28%;
        min-width: 100px;
    }
}