/* Navbar.css */

/* General Styles */

.navbar {
    position: fixed;
    width: 100%;
    background:linear-gradient(80deg, #1b0004,#380008,#5e010f,#4d000b,#4d000b,#4d000b);
    background-size:200% 100%;
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 0px;
    top: 0;
    box-shadow: 0 0 13px 0px #000;
    animation: navbar_background_color_rotate 5s infinite linear;
}

.small-device-navbar{
    position: fixed;
    width: 100%;
    background:linear-gradient(80deg, #1b0004,#380008,#5e010f,#4d000b,#4d000b,#4d000b);
    background-size:200% 100%;
    color: white;
    z-index: 1;
    align-items: center;
    padding: 5px 0px;
    top: 0;
    box-shadow: 0 0 10px 0px #000;
    display: none;
    border-bottom-left-radius:15px;
    border-bottom-right-radius:15px;
    animation: navbar_background_color_rotate 5s infinite linear;
}

@keyframes navbar_background_color_rotate {
    0%{
        background-size:200% 0%;
    }
    20%{
        background-size:100% 100%;
    }
    40%{
        background-size:200% 200%;
    }
    60%{
        background-size:200% 200%;
    }
    100%{
        background-size:200% 200%;
    }
}


.small-navbar {
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    width: 100%;
}

.small-navbar.navbar-logo{
    margin-left: 20px;
}

/* dropdown start */
.dropdown-container {
    position: relative;
}

.dropdown-toggle {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
}

.dropdown-toggle:hover {
    color: #fff;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;
    background-color: #000;
    border: 1px solid #c7253e;
    list-style: none;
    padding: 0.5rem 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.dropdown-menu li {
    margin: 0.5rem 0;
}

.dropdown-menu li a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    transition: color 0.3s;
}

.dropdown-menu li a:hover {
    color: #c7253e;
}
/* dropdown end */

.navbar-logo img {
    width: 150px;
    height: auto;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    display: flex;
    align-items: center;
}

.navbar-links a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
    transition: color 0.3s;
    position: relative;
}

.navbar-links a span {
    display: block;
}

.navbar-links a:hover {
    color: #c7253e;
    font-weight: 500;
}

.navbar-links .active {
    color: #c7253e;
    font-weight: 500;
}

.navbar-links .active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 2px;
    background-color: #821131;
    display: block;
}

.dropdown-menu ul.all-social-icon{
    width: 220px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left:0px;
}

.navbar-links .dropdown-menu ul.all-social-icon li{
    margin: 0px;
}

.navbar-links .dropdown-menu ul.all-social-icon li a{
    font-size:10px;
    margin: 0px;
}

.navbar-links .dropdown-menu ul.all-social-icon li a i{
    font-size:13px;
}

.navbar-links .dropdown-menu ul.all-social-icon li a p{
    display:none;
}

.redirect_navbar_menu{
    background-color: #821131;
    border: none;
    padding:3.5px 8px;
    border-radius:5px;
}

.redirect_navbar_menu a{
    width: 100%;
    display:inline;
    text-align: center;
}

.add_contact_install{
    width: 100%;
    justify-content: space-between;
}

.add_to_contact a{
    background-color: #821131;
    border: none;
    font-size:11px !important;
    font-weight:700;
    padding:0px;
    border-radius:5px;
}

.add_to_contact a:hover{
    font-weight: 700;  
    font-size:11px !important; 
}

@media (min-width: 991px) {
    .navbar {
        flex-direction: row;
        justify-content: space-around;
        top: 0;
        bottom: unset;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar {
        flex-direction: row;
        justify-content: space-around;
        top: 0;
        bottom: unset;
    }

    .navbar-links a,button {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        justify-content: space-around;
        top: unset;
        position: fixed;
        width: 100%;
        bottom: 0;
        border-top-left-radius:15px;
        border-top-right-radius:15px;
    }

    .navbar .navbar-logo{
        display:none;
    }

    .navbar .more-option{
        display: none;
    }

    .small-device-navbar{
        display: flex;
    }

    .navbar-links {
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
    }
}

@media (min-width: 561px) and (max-width: 768px) {
    .navbar-links a,button {
        flex-direction: row;
        font-size: 12px;
    }
    .navbar-logo img {
        width: 120px;
        height: auto;
    }
}

/* For screens between 421px to 561px */
@media (min-width: 421px) and (max-width: 561px) {
    .navbar-links a,button {
        flex-direction: column;
        font-size:13px;
    }
}


@media (max-width: 421px) {
    .navbar-logo img {
        width: 120px;
        height: auto;
    }

    .navbar-links a,button {
        flex-direction: column;
    }

    .navbar-links a:not(.active) span {
        display: none; 
    }

    .navbar-links a:not(.active) i {
        font-size: 24px;
    }

    .navbar-links .active span {
        display: block;
        font-size:10px;
    }

    .navbar-links .active i {
        font-size: 24px;
    }

    .navbar-links button{
        font-size: 13px;
    }

    .navbar-links .dropdown-menu ul.all-social-icon li a i{
        font-size:11px;
    }
}

