/* Container Styles */
.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(80deg, #200005, #380008);
    color: #fff;
    font-family: "Poppins", sans-serif;
    text-align: center;
    overflow: hidden;
}

/* Content Styles */
.error-page-content {
    animation: fadeIn 2s;
    padding: 30px;
    padding-top: 0px;
}

.error-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    letter-spacing: 2px;
    background: linear-gradient(90deg, #fff, #0092a5, #fff);
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientShift 6s ease-in-out infinite;
}

.error-message {
    font-size: 1.5rem;
    color: #ccc;
    margin-bottom: 1rem;
}

.error-description {
    font-size: 1.5rem;
    color: #aaa;
    margin-bottom: 2rem;
}

/* Loader Animation */
.error-page-container .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
}

.error-page-container .loader span {
    font-size: 26px;
    color: #0092a5;
    animation: bounce 1s infinite ease-in-out;
}

.error-page-container .loader span:nth-child(1) {
    animation-delay: 0s;
}

.error-page-container .loader span:nth-child(2) {
    animation-delay: 0.2s;
}

.error-page-container .loader span:nth-child(3) {
    animation-delay: 0.4s;
}

.error-page-container .loader span:nth-child(4) {
    animation-delay: 0.6s;
}

.error-page-container .loader span:nth-child(5) {
    animation-delay: 0.8s;
}

/* Keyframes for Loader */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

/* Home Button Styles */
.error-home-button {
    display: inline-block;
    background-color: #004750;
    color: #fff;
    font-size: 1.2rem;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

.error-home-button:hover {
    color: #004750;
    background-color: #fff;
}

.error-home-button:focus {
    outline: none;
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width:891px) {
    .error-description {
        font-size: 1.3rem;
    }
}

@media (max-width: 768px) {
    .error-title {
        font-size: 3rem;
    }

    .error-message {
        font-size: 1.3rem;
    }

    .error-description {
        font-size: .95rem;
    }
}

@media (max-width: 561px) {
    .error-title {
        font-size: 3rem;
    }

    .error-message {
        font-size: 1.2rem;
    }

    .error-description {
        font-size: 1.2rem;
    }
}

@media (max-width: 421px) {
    .error-title {
        font-size: 3rem;
    }

    .error-message {
        font-size: 1.6rem;
    }

    .error-description {
        font-size: 1rem;
    }
}
