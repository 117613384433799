body{
  margin: 0px 0px;
  font-family: "montserrat-medium", sans-serif;
}

/* Customizing the scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #575757;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #350000;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
