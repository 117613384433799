.service-sub-part {
    font-family: Arial, sans-serif;
    color: #131313;
    line-height: 1.6;
    padding: 80px 30px 20px 30px;
    background: linear-gradient(80deg, #1b0004, #380008);
}

.service-sub-part .page-header {
    text-align: center;
    background: linear-gradient(80deg, #1b0004, #380008);
    padding: 20px 10px;
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #ddd;
    border-radius: 10px;
}

.service-sub-part .breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    justify-content: center;
}

.service-sub-part .breadcrumb-link {
    text-decoration: none;
    color: #fff;
    background: #004750;
    padding:0px 10px;
    border-radius: 5px;
}

.service-sub-part .breadcrumb-link:hover {
    text-decoration: underline;
}

.service-sub-part .breadcrumb-separator {
    margin: 0px 5px;
    padding-top: 6px;
    color: #30918c;
    font-size: 24px;
}

.service-sub-part .breadcrumb-current {
    color: #aaa;
    background: #002f35;
    padding:2px 8px;
    border-radius: 5px;
}


.service-sub-part .page-header h1 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #dddddd;
}

.service-sub-part .page-header p {
    font-size: 20px;
    color: #afafaf;
}

.service-sub-part .service-overview {
    margin: 20px 0;
    text-align: center;
}

.service-sub-part .service-overview h2 {
    font-size: 16px;
    color: #fff;
    display: inline-block;
}

.service-sub-part .service-overview h2::before {
    content: "";
    display: block;
    height: 2px;
    background: linear-gradient(80deg, #ffffff, #30918c, #ffffff);
    margin-top: 10px;
    margin-bottom: 5px;
    animation: slideLine 3s infinite;
}

.service-sub-part .service-overview h2::after {
    content: "";
    display: block;
    height: 2px;
    background: linear-gradient(80deg, #ffffff, #30918c, #ffffff);
    margin-top: 5px;
    animation: slideLine 3s infinite;
}

.service-sub-part .service-overview p {
    font-size: 16px;
    color: #c5c5c5;
}

.service-sub-part .key-features {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
}

.service-sub-part .feature-item {
    padding: 20px;
    background: linear-gradient(80deg, #1b0004, #3b0009);
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #ddd;
    border-radius: 10px;
    text-align: center;
}

.service-sub-part .feature-item .service-icon {
    font-size: 40px;
    color: #ddd;
    display: inline-flex;
}

.service-sub-part .feature-item h3 {
    font-size: 20px;
    color: #ddd;
    margin-bottom: 10px;
}

.service-sub-part .feature-item p {
    font-size: 15px;
    color: #aaa;
}

.service-sub-part .choose-get-combine{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    gap: 20px;
}

.service-sub-part .why-choose-us {
    margin: 40px 0;
    background: #def2f1;
    padding: 20px;
    border-radius: 5px;
}

.service-sub-part .why-choose-us h2 {
    font-size: 24px;
    margin-bottom: 15px;
}

.service-sub-part .why-choose-us .reasons-list {
    list-style-type: none;
    padding: 0;
}

.service-sub-part .why-choose-us .reasons-list li {
    display: flex;
    color: #444;
    align-items: center;
    margin: 10px 0;
    font-size: 15px;
}

.service-sub-part .why-choose-us .reasons-list li svg {
    margin-right: 10px;
    color: #380008;
    font-size: 1.2em;
}

.service-sub-part .cta-section {
    text-align: center;
    margin: 40px 0;
    padding: 20px;
    background: #002f35;
    color: #fff;
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #def2f1;
    border-radius: 5px;
}

.service-sub-part .cta-section h2 {
    font-size: 22px;
    margin-bottom: 15px;
}

.service-sub-part .cta-section p {
    font-size: 15px;
    color:#ddd;
    margin-bottom: 20px;
}

.service-sub-part .cta-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background: #2b7a78;
    text-decoration: none;
    border-radius: 5px;
}

.service-sub-part .cta-button:hover {
    background: #3aafa9;
}

/* Media Queries for Responsiveness */
@media (max-width:1200px) {
    .service-sub-part .key-features {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media (max-width:891px) {
    .service-sub-part .why-choose-us .reasons-list li {
        font-size: 13px;
    }
}

@media (max-width:768px) {
    .service-sub-part {
        padding: 70px 30px 10px 30px;
    }

    .service-sub-part .page-header h1 {
        font-size: 32px;
    }

    .service-sub-part .page-header p {
        font-size: 16px;
    }

    .service-sub-part .key-features {
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }

    .service-sub-part .choose-get-combine{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }

    .service-sub-part .why-choose-us {
        margin-bottom: 5px;
    }

    .service-sub-part .why-choose-us .reasons-list li {
        font-size: 15px;
    }

    .service-sub-part .cta-section {
        margin-top: 5px;
    }
}

@media (max-width:691px) {
    .service-sub-part .key-features {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }
}

@media (max-width:691px) and (min-width:561px) {
    .service-sub-part .feature-item h3 {
        font-size: 16px;
    }
    
    .service-sub-part .feature-item p {
        font-size: 12px;
    }
}

@media (max-width:561px) {
    .service-sub-part {
        padding: 80px 30px 30px 30px;
    }

    .service-sub-part .page-header h1 {
        font-size: 24px;
    }

    .service-sub-part .key-features {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .service-sub-part .feature-item h3 {
        font-size: 20px;
    }

    .service-sub-part .feature-item p {
        font-size: 15px;
    }

    .service-sub-part .why-choose-us .reasons-list li {
        font-size: 14px;
    }
}

@media (max-width:421px) {
    .service-sub-part {
        padding: 70px 20px 30px 20px;
    }

    .service-sub-part .breadcrumbs {
        font-size: 12px;
    }
    
    .service-sub-part .breadcrumb-link {
        padding:0px 6px;
    }

    .service-sub-part .breadcrumb-separator {
        padding-top: 5px;
        font-size: 20px;
    }

    .service-sub-part .breadcrumb-current {
        padding:1px 6px;
    }

    .service-sub-part .page-header h1 {
        font-size: 20px;
    }

    .service-sub-part .key-features {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .service-sub-part .choose-get-combine{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}
