/* Container Styles */
.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(80deg, #200005, #380008);
    color: #fff;
    font-family: "Poppins", sans-serif;
    text-align: center;
    overflow: hidden;
}

/* Content Styles */
.coming-soon-content {
    animation: fadeIn 2s;
    padding: 30px;
    padding-top: 0px;
}

.coming-soon-title {
    font-size: 2.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
    letter-spacing: 2px;
    background: linear-gradient(90deg, #fff, #0092a5, #fff);
    background-size: 300%;
    -webkit-background-clip: text; /* Makes the gradient visible within the text */
    -webkit-text-fill-color: transparent; /* Ensures only the gradient is visible */
    animation: gradientShift 6s ease-in-out infinite;
}

.coming-soon-text {
    font-size: 1.5rem;
    color:#aaa;
    margin-bottom: 2rem;
}

/* Loader Animation */
.coming-soon-container .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.coming-soon-container .loader span {
    display: block;
    /* width: 15px;
    height: 15px; */
    font-size: 26px;
    color: #0092a5;
    border-radius: 50%;
    animation: bounce 1s infinite ease-in-out;
}

.coming-soon-container .loader span:nth-child(1) {
    animation-delay: 0s;
}

.coming-soon-container .loader span:nth-child(2) {
    animation-delay: 0.2s;
}

.coming-soon-container .loader span:nth-child(3) {
    animation-delay: 0.4s;
}

.coming-soon-container .loader span:nth-child(4) {
    animation-delay: 0.6s;
}

.coming-soon-container .loader span:nth-child(5) {
    animation-delay: 0.8s;
}

/* Home Button Styles */
.coming-soon-container .home-btn {
    display: inline-block;
    background-color: #004750;
    color: #fff;
    font-size: 1.2rem;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    margin-top: 2rem;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

.coming-soon-container .home-btn:hover {
    color: #004750;
    background-color:#fff;
}

.coming-soon-container .home-btn:focus {
    outline: none;
}


@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Keyframes for Loader */
@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width:768px) {
    .coming-soon-title {
        font-size: 2rem;
    }
    
    .coming-soon-text {
        font-size: 1.3rem;
    }

    .coming-soon-container .home-btn {
        margin-top: 1.5rem;
    }
}

@media (max-width:561px) {
    .coming-soon-title {
        font-size: 1.4rem;
    }
    
    .coming-soon-text {
        font-size: 1.3rem;
    }
}

@media (max-width:421px) {
    .coming-soon-title {
        font-size: 1.1rem;
    }
    
    .coming-soon-text {
        font-size: 1rem;
    }

    .coming-soon-container .home-btn {
        margin-top: 1rem;
    }
}