/* ServicePage.css */

.service-page {
    font-family: Arial, sans-serif;
    color: #131313;
    line-height: 1.6;
    padding: 80px 30px 20px 30px;
    background: linear-gradient(80deg, #1b0004, #380008);
}

.service-page .service-header {
    text-align: center;
    background: linear-gradient(80deg, #1b0004, #380008);
    padding: 20px 10px;
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #ddd;
    border-radius: 10px;
}

.service-page .service-header h1 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #dddddd;
}

.service-page .service-header p {
    font-size: 20px;
    color: #afafaf;
}

.service-page .services-overview {
    margin: 20px 0;
    text-align: center;
}

.service-page .services-overview h2 {
    font-size: 16px;
    color: #fff;
    display: inline-block;
}

.service-page .services-overview h2::before {
    content: "";
    display: block;
    height: 2px;
    background: linear-gradient(80deg, #ffffff, #30918c, #ffffff);
    margin-top: 10px;
    margin-bottom: 5px;
    animation: slideLine 3s infinite;
}

.service-page .services-overview h2::after {
    content: "";
    display: block;
    height: 2px;
    background: linear-gradient(80deg, #ffffff, #30918c, #ffffff);
    margin-top: 5px;
    animation: slideLine 3s infinite;
}

.service-page .services-overview p {
    font-size: 16px;
    color: #c5c5c5;
}

.service-page .service-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    margin: 20px 0;
}

.service-page .service-item {
    padding: 20px;
    background: linear-gradient(80deg, #1b0004, #3b0009);
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #ddd;
    border-radius: 10px;
    text-decoration:none;
}

.service-page .service-item .service-icon {
    font-size: 40px;
    color: #ddd;
    display: flex;
}

.service-page .service-item h3 {
    font-size: 20px;
    color: #ddd;
    margin-bottom: 10px;
}

.service-page .service-item .maximize-icon {
    display: inline-block;
    transform: translateY(5px);
    margin-left: 5px;
    font-size:24px;
    transition: transform 0.3s ease-in-out;
}

.service-page .service-item:hover .maximize-icon {
    transform: translateX(5px);
    margin-left: 5px;
}

.service-page .service-item p {
    font-size: 0.95em;
    color: #aaa;
}

.service-page .why-build-combine{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    gap: 20px;
}

.service-page .why-choose-us {
    margin: 40px 0;
    background: #def2f1;
    padding: 20px;
    border-radius: 5px;
}

.service-page .why-choose-us h2 {
    font-size: 2em;
    margin-bottom: 15px;
}

.service-page .why-choose-us .icon-list {
    list-style-type: none;
    padding: 0;
}

.service-page .why-choose-us .icon-list li {
    display: flex;
    color: #444;
    align-items: center;
    margin: 10px 0;
}

.service-page .why-choose-us .list-icon {
    margin-right: 10px;
    color: #380008;
    font-size: 1.2em;
}

.service-page .cta-section {
    text-align: center;
    margin: 40px 0;
    padding: 20px;
    background: #002f35;
    color: #fff;
    box-shadow: 0px 0px 5px 0px #555;
    border-bottom: 2px solid #def2f1;
    border-radius: 5px;
}

.service-page .cta-section h2 {
    font-size: 2em;
    margin-bottom: 15px;
}

.service-page .cta-section p {
    font-size: 1em;
    margin-bottom: 20px;
}

.service-page .cta-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background: #2b7a78;
    text-decoration: none;
    border-radius: 5px;
    transition: backgroundbutton 0.3s;
}

.service-page .cta-button:hover {
    background: #3aafa9;
}

@media (max-width:1200px) {
    .service-page .service-list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media (max-width:768px) {
    .service-page {
        padding: 70px 30px 10px 30px;
    }

    .service-page .service-header h1 {
        font-size: 32px;
    }

    .service-page .service-header p {
        font-size: 16px;
    }

    .service-page .service-list {
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }

    .service-page .why-build-combine{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    
    .service-page .why-choose-us {
        margin-bottom:5px;
    }

    .service-page .cta-section {
        margin-top: 5px;
    }
}

@media (max-width:691px) {
    .service-page .service-list {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }
    
    .service-page .service-item h3 {
        font-size: 18px;
    }
    
    .service-page .service-item p {
        font-size: 14px;
    }
}

@media (max-width:611px) {
    .service-page .service-list {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }

    .service-page .service-item h3 {
        font-size: 16px;
    }
    
    .service-page .service-item p {
        font-size: 12px;
    }
}

@media (max-width:561px) {
    .service-page {
        padding: 80px 30px 30px 30px;
    }

    .service-page .service-header h1 {
        font-size: 24px;
    }

    .service-page .service-list {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .service-page .service-item h3 {
        font-size: 20px;
    }
    
    .service-page .service-item p {
        font-size: 15px;
    }
}

@media (max-width:421px) {
    .service-page {
        padding: 70px 20px 30px 20px;
    }

    .service-page .service-header h1 {
        font-size: 20px;
    }

    .service-page .why-build-combine{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}