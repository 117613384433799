.install_button .button-color {
    padding: 2px 0px;
    background-color: #821131;
    color: #fff;
    font-weight: 700;
    position: relative;
    border: none;
    font-size: 11px;
    border-radius: 5px;
    cursor: pointer;
}

.redirect_navbar_menu.install_button {
    display: block !important;
}

.install-highlight {
    background-image: linear-gradient(120deg, #fff, #fff, #fff, #fff, #000, #fff);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    animation: digitalformlinkhighlight 6s infinite ease-in-out;
}

@keyframes digitalformlinkhighlight {
    0% {
        background-position: 200% 0;
    }

    20% {
        background-position: 50% 0;
    }

    40% {
        background-position: -200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}