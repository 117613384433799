/* .homepage {
    width: 100%;
    margin: 0 auto;
    overflow-x:hidden;
} */

/* Hero Section */
.hero {
    display: flex;
    align-items: center;
    justify-content:left;
    text-align: left;
    background: linear-gradient(80deg, #1b0004, #380008);    
    min-height: 100vh;
    width: 100%;
}

.hero img.home-page-background{
    filter: brightness(.4) saturate(1);
    height:100vh;
    width: 100%;
    object-fit:cover;
    position: fixed;
}

.hero-content{
    padding-left: 150px;
    padding-top: 100px;
    position: relative;
}

.hero-content .welcome-text {
    font-size: 20px;
    font-weight:600;
    color:#a4a4a4;
    margin-bottom: 20px;
}

.hero-content .powerfull-text {
    color:#fff;
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 30px;
}

.service-contact{
    display:flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
}

.service-contact .both_button{
    text-align: center;
    width:48%;
    border-radius: 5px;
    border: 2px solid #821131;
    transition: background-color 0.3s;
    overflow: hidden;
}


.btn-primary {
    display: flex;
    justify-content: center;
    align-items: center; 
    color:#fff;
    font-size: 18px;        
    padding:10px;
    text-decoration: none;
    border-radius: inherit; 
}

.both_button:hover {
    background-color: #821131;
}

.home-social-icon .all-social-icon{
    display:flex;
    justify-content: space-around;
    padding-left: 0px;
    margin-bottom: 30px;
    align-items: center;
}

.home-social-icon .all-social-icon li{
    list-style: none;
    text-align: center;
}

.home-social-icon .all-social-icon a i{
    text-align: center;
    border-radius: 100%;    
    padding: 13px;
    border: 2px solid #821131;
    transition: background-color 0.3s;
    color:#fff;
    font-size: 14px;
    text-decoration: none;
}

.home-social-icon .all-social-icon a{
    text-decoration: none;
}

.home-social-icon .all-social-icon a p{
    color:#fff;
    text-decoration: none;
    font-size:15px;
    padding-top: 5px;
    text-align: center;
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 0%;    
    transition: all 0.5s ease-in-out;
    visibility: hidden;
}

.home-social-icon .all-social-icon a:hover i{
    background-color: #821131;
}

.home-social-icon .all-social-icon a:hover p{
   visibility: visible;
   opacity: 1;
   transform: scale(1);
   text-decoration:none;
}

@media (max-width:1491px) {
    .hero-content {
        padding-left:100px;
    }
}

@media (max-width:1091px) {
    .hero-content {
        padding-left:50px;
    }
    .hero-content .powerfull-text {
        font-size: 50px;
    }
}

@media (max-width:991px) {
    .hero-content .powerfull-text {
        font-size: 45px;
    }
}

@media (max-width:768px) {
    .hero {
        justify-content: center;
        text-align: center;
    }
    .hero-content{
        padding-left: 0px;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .service-contact{
        justify-content: space-between;
    }
}

@media (max-width:561px) {
    .hero-content .welcome-text {
        font-size: 15px;
    }
    .hero-content .powerfull-text {
        font-size: 40px;
    }
    .home-social-icon .all-social-icon a p{
        font-size:12px;
    }
}

@media (max-width:421px) {
    .hero {
        min-height: 100vh;
    }
}

@media (max-width:391px) {
    .hero-content .welcome-text {
        font-size: 13px;
    }
    .hero-content .powerfull-text {
        font-size: 32px;
    }
    .btn-primary {
        font-size: 13px;
    }
    .home-social-icon .all-social-icon a p{
        font-size:10px;
    }
}
